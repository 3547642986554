import * as Highcharts from 'highcharts/highstock';

export enum LegacyUserLevel {
  User = 'User',
  ChiefUser = 'ChiefUser',
  ProjectLeader = 'ProjectLeader',
  Manager = 'Manager',
  Administrator = 'Administrator',
  Invisible = 'Invisible',
}

export enum SupportedViewKinds {
  Chart = 'Chart',
  Dashboard = 'Dashboard',
  LiveImage = 'LiveCam',
  DataloggerControl = 'DataLoggerControl',
  ImageViewer = 'ImageViewer',
  ControlCenter = 'StandardControlCenter',
  ObservationMap = 'ObservationMap',
  ThermistorChain = 'ThermistorChain',
  MeasurementMap = 'MeasurementMap',
  Html = 'Html',
  Raw = 'Raw',
  ExternalLink = 'ExternalLink',
  MessengerLists = 'MessengerLists', // keep until old data-portal is shut-down!
  RaumaControlCenter = 'RaumaControlCenter',
}

export enum StationStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Standby = 'StandBy',
}

export interface IStationsResponseDto {
  stations: IStationSummaryDto[] | null;
}

export interface IStationSummaryDto {
  id: string;
  name: string;
  defaultView: string | null;
  timeZone: string;
  modeOfOperation: StationStatus;
  hasUnreadMessages: boolean;
}

export interface IStationDto extends IStationSummaryDto {
  views: IViewSummaryDto[];
  features: IPanelDto;
}

export interface IPanelDto {
  showSms: boolean;
  showStationInfo: boolean;
  showStatusConfig: boolean;
  showExport: IExportPanelDto | null;
  showMessageEditor: IMessengerEditorDto | null;
}

export interface IMessengerEditorDto {
  title: string;
}

export interface IExportPanelDto {
  title: string;
}

export interface IViewSummaryDto {
  id: string;
  kind: SupportedViewKinds;
  title: string;
  sortKey: number;
  requiredUserLevel: LegacyUserLevel;
}

export interface TimeSeriesResponse {
  series: TimeSerie[];
}

export interface IThermistorSeriesResponse {
  series: IThermistorSerie[];
}

export interface ISeriesResponse<T> {
  series: T[];
  lastMeasurementAt: T extends INavigatorSerie ? string | null : never; // ISO timestamp
}

export type DataApproximation = 'AVG' | 'MIN' | 'MAX';

export interface GPTimeSerieProperties {
  serverSideDataApproximation?: DataApproximation;
}

export interface INavigatorSerie {
  data: number[][];
}

export interface NavigatorSeriesRange {
  minTimestamp: string;
  maxTimestamp: string;
}

export interface TimeSerie {
  name?: string;
  id?: string;
  data: DataPoint;
  gp?: GPTimeSerieProperties;
}

export type DataPoint = [number, number | null][];

export interface IThermistorSerie {
  name: string;
  data: IThermistorSeriesPoint[];
}

export interface IThermistorSeriesPoint {
  x: number;
  y: number;
  custom: {
    count: number;
  };
}

export interface ChartResponse {
  kind: SupportedViewKinds.Chart;
  id: string;
  title: string;
  data: {
    highStockOptions: Highcharts.Options;
    lastMeasurementAt: string;
    htmlInfo: string;
  };
}

export interface DashboardResponse {
  kind: SupportedViewKinds.Dashboard;
  id: string;
  title: string;
  data: {
    linkedViews: ViewResponse[];
    initialDuration: string; // ISO-Duration
    showTimeSeriesLegends: boolean;
  };
}

export interface LiveImageResponse {
  kind: SupportedViewKinds.LiveImage;
  id: string;
  title: string;
  data: {
    liveUrl: string;
  };
}

export interface DataloggerControlResponse {
  kind: SupportedViewKinds.DataloggerControl;
  id: string;
  title: string;
  data: {
    proxyUrl: string;
    autoTerminateAfter?: string;
  };
}

export interface ImageViewerResponse {
  kind: SupportedViewKinds.ImageViewer;
  id: string;
  title: string;
  data: {
    source: string;
    allowQualityFiltering: boolean;
    initialMinQuality: number;
    liveUrl: string;
    htmlInfo: string;
  };
}

export interface ControlCenterResponse {
  kind: SupportedViewKinds.ControlCenter;
  id: string;
  title: string;
  data: {
    targetDataLoggerId?: string;
  };
}

export interface ObservationMapResponse {
  kind: SupportedViewKinds.ObservationMap;
  id: string;
  title: string;
  data: {
    detectorIds: string[];
    initialDuration: string;
    mapBackground: string;
    kmlFilePath?: string;
    showTrajectoriesForAllUsers: boolean;
  };
}

export interface ThermistorChainResponse {
  kind: SupportedViewKinds.ThermistorChain;
  id: string;
  title: string;
  data: {
    highStockOptions: Highcharts.Options;
    htmlInfo: string;
  };
}

export interface IMeasurement {
  id: string;
  name: string;
  shortName: string;
  unit: string;
  series: TimeSerie;
  lastDataPoint: {
    timestamp: string; // ISO-String
    value: number | null; // datapoint can be a null value!
  } | null;
}

export interface IMeasurementGroup {
  dataLoggerId: string;
  dataLoggerDisplayName: string;
  latitude: number;
  longitude: number;
  measurements: IMeasurement[];
}

export interface MeasurementMapResponse {
  kind: SupportedViewKinds.MeasurementMap;
  id: string;
  title: string;
  data: {
    groups: IMeasurementGroup[];
    mapBackground: string;
    initialDuration: string;
    labelRotation: number;
    decimalDigits: number;
  };
}

export interface IExternalLinkResponse {
  id: string;
  kind: SupportedViewKinds.ExternalLink;
  title: string;
  data: {
    url: string;
  };
}

export interface IHtmlInfoResponse {
  id: string;
  kind: SupportedViewKinds.Html;
  title: string;
  data: {
    content: string; // contains HTML!
  };
}

export interface IMessengerListsResponse {
  kind: SupportedViewKinds.MessengerLists;
  id: string;
  title: string;
}

export interface IStationMessageDto {
  id: string;
  author: string; // full name of user that posted the message
  timestamp: string; // ISO string in UTC
  readAt: string | null; // ISO string in UTC
  isInternal: boolean;
  message: string; // contains HTML
}

export interface IStationMessagesResponseDto {
  data: IStationMessageDto[];
  totalCount: number;
}

export interface IFavouriteStationsResponseDto {
  stations: string[];
}

export interface SignalMapDto {
  signalId: string;
  signalType: 'TrafficLight' | 'Horn';
}

export interface RaumaControlCenterResponseDto {
  kind: SupportedViewKinds.RaumaControlCenter;
  id: string;
  title: string;
  data: {
    applianceId: string;
    signalMappings: SignalMapDto[];
  };
}

export type ViewResponse =
  | DashboardResponse
  | ChartResponse
  | LiveImageResponse
  | DataloggerControlResponse
  | ImageViewerResponse
  | ControlCenterResponse
  | ObservationMapResponse
  | ThermistorChainResponse
  | MeasurementMapResponse
  | IExternalLinkResponse
  | IMessengerListsResponse
  | IHtmlInfoResponse
  | RaumaControlCenterResponseDto;
export interface RawDataExportOrdersResponseDto {
  exportOrders: RawDataExportOrderDto[];
}

export type RawDataExportOrdersResponse = RawDataExportOrder[];

export enum RawDataExportOrderProgress {
  Pending = 'Pending',
  Processing = 'Processing',
  Completed = 'Completed',
  Failed = 'Failed',
}

export type SimpleMeasurement = Pick<IMeasurement, 'id' | 'name'>;

export interface RawDataExportOrderDto {
  timestamp: string;
  progress: string;
  measurements: SimpleMeasurement[];
  exportFrom: string;
  exportTo: string;
  downloadUrl?: string;
}

export interface MeasurementsResponse {
  measurements: SimpleMeasurement[];
}

export interface RawDataExportOrder {
  timestamp: Date;
  progress: RawDataExportOrderProgress;
  measurements: SimpleMeasurement[];
  exportFrom: Date;
  exportTo: Date;
  downloadUrl?: string;
}

export interface ViewLocatorResponse {
  viewId: string;
}
